<template>
  <div>
    <div v-if="collections.length">
      <div class="flex items-center relative mb-10">
        <vs-button
          @click="addNewData()"
          class="
            btn-add-new
            p-3
            mr-8
            rounded-lg
            cursor-pointer
            flex
            items-center
            justify-center
          "
        >
          <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
          <span class="ml-2">إصافة جديد</span>
        </vs-button>
        <!-- Input -->
        <vs-input
          ref="input"
          class="z-50 w-1/2"
          icon-pack="feather"
          icon="icon-search"
          icon-no-border
          placeholder="بحث عن مجموعة"
          v-model="searchQuery"
        />
      </div>
      <div class="vx-row">
        <div
          class="vx-col w-full sm:w-1/2 lg:w-1/3 xl:w-1/3 md:w-1/3 mb-base"
          v-for="collection in collections"
          :key="collection.id"
        >
          <vx-card>
            <div slot="no-body">
              <img
                :src="collection.media"
                class="responsive card-img-top"
              />
            </div>
            <p class="text-grey">{{ collection.description }}</p>
            <div class="flex justify-between flex-wrap">
              <vs-button
                class="mt-4 mr-2 shadow-lg"
                color="primary"
                :to="{
                  name: 'collections-edit',
                  params: { id: collection.id },
                }"
                >تعديل</vs-button
              >
              <vs-button
                class="mt-4"
                type="border"
                color="danger"
                @click="confirm(collection.id)"
                >أرشفة</vs-button
              >
            </div>
          </vx-card>
        </div>
      </div>
    </div>
    <EmptyState
      :link="addNewData"
      page="المجموعات"
      display="true"
      v-else
    ></EmptyState>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EmptyState from "@/views/emptyState.vue";
export default {
  components: {
    EmptyState,
  },
  data() {
    return {
      searchQuery: "",
      collection_id: "",
    };
  },

  computed: {
    collections() {
      return this.$store.state.collections.collections;
    },
  },
  methods: {
    ...mapActions("collections", ["fetchCollections", "archiveCollection"]),
    confirm(id) {
      this.collection_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من أرشفة هذه المجموعة؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    addNewData(){
      this.$router.push({ name: 'collections-add' })
    },
    deleteData() {
      this.archiveCollection(this.collection_id)
        .then((response) => {
          this.successDialog(response.data.message);
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  created() {
    this.fetchCollections();
  },
};
</script>

<style lang="scss" scoped>
.card-img-top{
  height: 300px;
}
</style>
